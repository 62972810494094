import React, { useEffect, useState } from "react";
import {
  FaEnvelope,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import emailjs from "emailjs-com";
import photo from "../assets/photos/contact-photo.jpg";
import contactData from "../data/contactData";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    projectType: "",
    source: "",
    message: "",
    interests: {
      granite: false,
      quartz: false,
      quartzite: false,
      marble: false,
      kitchen: false,
      bathroom: false,
      fireplace: false,
    },
  });

  const [submitted, setSubmitted] = useState(false);
  const [skillTest, setSkillTest] = useState({ question: "", answer: 0 });
  const [userAnswer, setUserAnswer] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Generate a new skill test question
  const generateSkillTest = () => {
    const num1 = Math.floor(Math.random() * 14) + 1;
    const num2 = Math.floor(Math.random() * 14) + 1;
    setSkillTest({ question: `${num1} + ${num2}`, answer: num1 + num2 });
  };

  useEffect(() => {
    generateSkillTest();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        interests: { ...prev.interests, [name]: checked },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSkillTestChange = (e) => {
    setUserAnswer(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate skill test
    if (parseInt(userAnswer) !== skillTest.answer) {
      setErrorMessage("Incorrect. Please try again.");
      setUserAnswer("");
      generateSkillTest();
      return;
    }

    setErrorMessage("");
    setSubmitted(true);

    const currentDateTime = new Intl.DateTimeFormat("en-CA", {
      timeZone: "America/Edmonton",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(new Date());

    const selectedInterests = Object.entries(formData.interests)
      .filter(([_, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(", ");

    const message = `Submitted on: ${currentDateTime}

Name: ${formData.name}
Email: ${formData.email}
Phone: ${formData.phone}
Type of project: ${formData.projectType}
How did you hear about us?: ${formData.source}
Which materials/services are you interested in?: ${selectedInterests || "None"}
Message:
${formData.message}`;
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        { message },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log("Email sent successfully!");
        },
        (error) => {
          console.error("Failed to send email:", error);
        }
      );
  };

  const { phone, email, address } = contactData.contactInfo;
  const { facebook, instagram } = contactData.socialLinks;

  return (
    <div className="contact-container">
      <div className="contact-left">
        <h1>Contact Us</h1>
        <div className="contact-container">
          <FaPhoneAlt className="contact-icon" />
          <a href={`tel:${phone}`} className="contact-data contact-data-link">
            {phone}
          </a>
        </div>
        <div className="contact-container">
          <FaEnvelope className="contact-icon" />
          <a
            href={`mailto:${email}`}
            className="contact-data contact-data-link"
          >
            {email}
          </a>
        </div>
        <div className="contact-container">
          <FaMapMarkerAlt className="contact-icon" />
          <div className="contact-data">{address}</div>
        </div>
        <div className="social-links">
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            <FaInstagram className="social-icon" size={"40px"} />
          </a>
        </div>
        <div>
          <img className="contact-photo" src={photo} alt="Slabs" />
        </div>
      </div>

      <div className="divider"></div>

      <div className="contact-right">
        <h2 className="form-header">Request a Free Estimate</h2>
        <p className="form-description">
          Please fill out the form below to receive a FREE estimate or
          consultation. We will contact you within 24 hours. To help us better
          serve you, please include a brief overview of the project.
        </p>
        <p className="form-description2">
          For immediate assistance, call <a href={`tel:${phone}`}>{phone}</a>.
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="name" className="contact-label">
            Name <span className="required">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            disabled={submitted}
          />
          <label htmlFor="email" className="contact-label">
            Email <span className="required">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            disabled={submitted}
          />
          <label htmlFor="phone" className="contact-label">
            Phone <span className="required">*</span>
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            disabled={submitted}
          />
          <label className="contact-label">
            Type of project <span className="required">*</span>
          </label>
          <select
            name="projectType"
            value={formData.projectType}
            onChange={handleChange}
            required
            disabled={submitted}
          >
            <option value="" disabled>
              Select an option
            </option>
            <option value="New Construction">New Construction</option>
            <option value="Renovation">Renovation</option>
            <option value="Repair">Repair</option>
          </select>
          <label className="contact-label">
            How did you hear about us? <span className="required">*</span>
          </label>
          <select
            name="source"
            value={formData.source}
            onChange={handleChange}
            required
            disabled={submitted}
          >
            <option value="" disabled>
              Select an option
            </option>
            <option value="Google">Google</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Word of Mouth">Word of Mouth</option>
            <option value="Other">Other</option>
          </select>
          <label className="contact-label">
            Message <span className="required">*</span>
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            required
            disabled={submitted}
          />
          <label className="contact-label">
            Which materials/services you are interested in?
          </label>
          <fieldset className="interests-fieldset" disabled={submitted}>
            <div>
              <label className="interests-fieldset-checkbox-container">
                <div className="interests-fieldset-checkbox">
                  <input
                    type="checkbox"
                    name="granite"
                    checked={formData.interests.granite}
                    onChange={handleChange}
                    disabled={submitted}
                  />
                </div>
                <div className="service-item">Granite</div>
              </label>
              <label className="interests-fieldset-checkbox-container">
                <div className="interests-fieldset-checkbox">
                  <input
                    type="checkbox"
                    name="quartz"
                    checked={formData.interests.quartz}
                    onChange={handleChange}
                    disabled={submitted}
                  />
                </div>
                <div className="service-item">Quartz</div>
              </label>
              <label className="interests-fieldset-checkbox-container">
                <div className="interests-fieldset-checkbox">
                  <input
                    type="checkbox"
                    name="quartzite"
                    checked={formData.interests.quartzite}
                    onChange={handleChange}
                    disabled={submitted}
                  />
                </div>
                <div className="service-item">Quartzite</div>
              </label>
              <label className="interests-fieldset-checkbox-container">
                <div className="interests-fieldset-checkbox">
                  <input
                    type="checkbox"
                    name="marble"
                    checked={formData.interests.marble}
                    onChange={handleChange}
                    disabled={submitted}
                  />
                </div>
                <div className="service-item">Marble</div>
              </label>
              <label className="interests-fieldset-checkbox-container">
                <div className="interests-fieldset-checkbox">
                  <input
                    type="checkbox"
                    name="kitchen"
                    checked={formData.interests.kitchen}
                    onChange={handleChange}
                    disabled={submitted}
                  />
                </div>
                <div className="service-item">Kitchen</div>
              </label>
              <label className="interests-fieldset-checkbox-container">
                <div className="interests-fieldset-checkbox">
                  <input
                    type="checkbox"
                    name="bathroom"
                    checked={formData.interests.bathroom}
                    onChange={handleChange}
                    disabled={submitted}
                  />
                </div>
                <div className="service-item">Bathroom</div>
              </label>
              <label className="interests-fieldset-checkbox-container">
                <div className="interests-fieldset-checkbox">
                  <input
                    type="checkbox"
                    name="fireplace"
                    checked={formData.interests.fireplace}
                    onChange={handleChange}
                    disabled={submitted}
                  />
                </div>
                <div className="service-item">Fireplace</div>
              </label>
            </div>
          </fieldset>
          <div className="form-footer">
            {!submitted && (
              <div className="skill-test-container">
                <label htmlFor="skill-test" className="skill-test-label">
                  {skillTest.question} =
                </label>
                <input
                  type="text"
                  id="skill-test"
                  value={userAnswer}
                  onChange={handleSkillTestChange}
                  required
                  maxLength={3}
                />
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </div>
            )}
            {submitted ? (
              <p className="thank-you-message">
                Thank you! We'll get back to you ASAP!
              </p>
            ) : (
              <button type="submit" className="submit-button">
                SUBMIT
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
