const contactData = {
  contactInfo: {
    phone: "+1 (403) 818-8961",
    email: "expressgraniteandquartz@gmail.com",
    address: "Calgary, AB",
  },
  socialLinks: {
    facebook: "https://facebook.com/expressgraniteandquartz",
    instagram: "https://instagram.com/expressgraniteandquartz",
  },
};

export default contactData;
