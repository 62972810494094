import React from "react";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import contactData from "../data/contactData"; // Import the contact data
import "./Footer.css"; // Add the import for the footer CSS file
import pinoy from "../assets/photos/pinoy.png";

const Footer = () => {
  const { contactInfo, socialLinks } = contactData; // Destructure the contact info and social links

  return (
    <footer className="footer">
      <div className="contact-info">
        <div>
          <FaPhoneAlt className="icon" />{" "}
          <a href={`tel:${contactInfo.phone}`} className="contact-data-link">
            {contactInfo.phone}
          </a>
        </div>
        <div>
          <FaEnvelope className="icon" />{" "}
          <a href={`mailto:${contactInfo.email}`} className="contact-data-link">
            {contactInfo.email}
          </a>
        </div>
        <div>
          <FaMapMarkerAlt className="icon" /> {contactInfo.address}
        </div>
      </div>
      <img src={pinoy} className="pinoy-photo" />
      <div className="social-links">
        {/* <a
          href={socialLinks.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className="social-icon" size={"40px"} />
        </a> */}
        <a
          href={socialLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="social-icon" size={"40px"} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
