import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import "./Header.css";
import logo from "../assets/branding/logo-full-black-2.png";
import logoSmall from "../assets/branding/logo-black.png";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation(); // Get current location

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false); // Close the drawer
  };

  // Close the drawer if the window is resized to a width > 768px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isDrawerOpen) {
        closeDrawer();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDrawerOpen]);

  // Helper function to determine if a link is active
  const isActive = (path) => (location.pathname === path ? "active" : "");

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Express Granite and Quartz Logo" />
          </Link>
        </div>
        {/* Hamburger Menu */}
        {!isDrawerOpen && (
          <button
            className="hamburger"
            onClick={toggleDrawer}
            aria-label="Menu"
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </button>
        )}
        {/* Desktop Nav Links */}
        <nav className="nav-links desktop-nav">
          <Link to="/" className={isActive("/")}>
            Home
          </Link>
          <Link to="/projects" className={isActive("/projects")}>
            Projects
          </Link>
          <Link to="/contact" className={isActive("/contact")}>
            Contact
          </Link>
          <Link to="/contact" className="nav-cta-button">
            REQUEST FREE ESTIMATE
          </Link>
        </nav>
      </div>

      {/* Overlay */}
      {isDrawerOpen && (
        <div className="drawer-overlay" onClick={closeDrawer}></div>
      )}

      {/* Mobile Drawer */}
      <div className={`mobile-drawer ${isDrawerOpen ? "open" : ""}`}>
        <nav className="mobile-nav">
          <Link to="/" className={isActive("/")} onClick={closeDrawer}>
            Home
          </Link>
          <Link
            to="/projects"
            className={isActive("/projects")}
            onClick={closeDrawer}
          >
            Projects
          </Link>
          <Link
            to="/contact"
            className={isActive("/contact")}
            onClick={closeDrawer}
          >
            Contact
          </Link>
          <Link
            to="/contact"
            className="cta-button drawer-cta"
            onClick={closeDrawer}
          >
            REQUEST FREE ESTIMATE
          </Link>
          <img
            src={logoSmall}
            className="drawer-logo"
            onClick={closeDrawer}
            alt="Logo"
          />
        </nav>
      </div>
    </header>
  );
};

export default Header;
