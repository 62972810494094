import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import PageTitleUpdater from "./components/PageTitleUpdater";
import ScrollToTop from "./components/ScrollToTop"; // Import the ScrollToTop component

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Add this to reset scroll position */}
      <PageTitleUpdater />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
