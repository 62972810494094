import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Express Granite & Quartz";
        break;
      case "/projects":
        document.title = "Projects - Express Granite & Quartz";
        break;
      case "/contact":
        document.title = "Contact Us - Express Granite & Quartz";
        break;
      default:
        document.title = "Express Granite & Quartz";
    }
  }, [location]);

  return null; // This component only updates the title and renders nothing
};

export default PageTitleUpdater;
