import React from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import photo from "../assets/photos/home-photo.jpg";
import "./Home.css";

const Home = () => (
  <div>
    <section className="attention-section">
      <div className="attention-text-container">
        <h2 className="attention-text">
          Your Experts for Residential and Commercial Stone Installation and
          Replacement in the Greater Calgary Area
        </h2>
        <div className="attention-caption">
          Let us help you bring your vision to life!
        </div>
      </div>
      <Link to="/contact" className="attention-cta-button">
        CONTACT US
      </Link>
    </section>
    <div className="home-container">
      <div className="home-content">
        <section className="about-section">
          <p className="hero-description">
            <strong>Express Granite and Quartz Ltd.</strong> is a family-owned
            business based in Calgary, Alberta, with over 20 years of experience
            in the granite and quartz industry. We pride ourselves on delivering
            high-quality surfaces with a focus on craftsmanship, reliability,
            and customer satisfaction.
          </p>
        </section>
        <section className="hero-section">
          <h1 className="hero-title">Our Services</h1>

          <p className="hero-description">
            Our team follows manufacturer guidelines, building codes, and
            industry standards to ensure every installation is durable and
            expertly executed. We are committed to providing beautiful,
            long-lasting surfaces that enhance your space.
          </p>
        </section>

        <section className="services-section">
          <ul className="services-list">
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>New constructions</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Renovations</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Crack/seam repairs </strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Full-height backsplash</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Color-matching</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Resizing of sink cutouts</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Demolition</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Custom hole-drilling</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Sink remounting</strong>
              </span>
            </li>
            <li className="service-item">
              <div className="icon-container">
                <FaCheck className="checkmark-icon" />
              </div>
              <span className="service-text">
                <strong>Design consultation</strong>
              </span>
            </li>
          </ul>
        </section>
      </div>

      <div className="home-photo-container">
        <img className="home-photo" src={photo} alt="Home Granite Example" />
      </div>
      <section className="consultation-section">
        <div className="consultation-text-container">
          <h2 className="consultation-text">
            Not sure where to start? Request your FREE consultation today!
          </h2>
          <div className="consultation-caption">
            When you call us for a consultation, we'll come to your home at a
            time that's convenient for you. Whether you're looking to start from
            scratch or enhance an existing space, we'll work with you to develop
            a plan that fits your needs and your budget.
          </div>
          <div>
            <b>Did you know?</b>
          </div>
          <ul>
            <li>
              Granite is a natural stone formed from cooled magma, making it
              incredibly durable and unique
            </li>
            <li>
              Quartz is engineered, combining natural quartz crystals with
              resins for non-porous, stain-resistant surfaces
            </li>
            <li>
              Both materials are highly scratch-resistant, suitable for
              high-traffic areas, and are long-lasting investments that add
              value to your property
            </li>
          </ul>
        </div>
        <Link to="/contact" className="consultation-cta-button">
          GET A FREE QUOTE
        </Link>
      </section>
    </div>
    <div className="home-photo-container-mobile">
      <img
        className="home-photo-mobile"
        src={photo}
        alt="Home Granite Example"
      />
      <Link to="/projects" className="home-photo-mobile-cta">
        SEE GALLERY
      </Link>
    </div>
    <section className="consultation-section-mobile">
      <div className="consultation-text-container">
        <h2 className="consultation-text">
          Not sure where to start? Request your FREE consultation today!
        </h2>
        <div className="consultation-caption">
          When you call us for a consultation, we'll come to your home at a time
          that's convenient for you. Whether you're looking to start from
          scratch or enhance an existing space, we'll work with you to develop a
          plan that fits your needs and your budget.
        </div>
        <div>
          <b>Did you know?</b>
        </div>
        <ul>
          <li>
            <b>Granite</b> is a natural stone formed from cooled magma, making it
            incredibly durable and unique
          </li>
          <li>
            <b>Quartz</b> is engineered, combining natural quartz crystals with resins
            for non-porous, stain-resistant surfaces
          </li>
          <li>
            <b>Quartzite</b> is a natural stone formed from sandstone under intense heat and pressure, offering the look of marble with superior strength and durability
          </li>
          <li>
            <b>Marble</b> is a metamorphic rock formed from limestone, prized for its elegant veining and timeless beauty, though softer than other natural stones
          </li>
        </ul>
      </div>
      <Link to="/contact" className="consultation-cta-button">
        GET A FREE QUOTE
      </Link>
    </section>
  </div>
);

export default Home;
