import React, { useState } from "react";
import "./Projects.css";

// Dynamically import all images from src/assets/projects
const importAll = (requireContext) => {
  return requireContext.keys().map(requireContext);
};
const images = importAll(
  require.context("../assets/projects", false, /\.(png|jpe?g|svg)$/)
);

const Projects = () => {
  const [selectedImage, setSelectedImage] = useState(null); // Tracks the image to show in the modal

  const openModal = (image) => {
    setSelectedImage(image); // Set the clicked image as the modal image
  };

  const closeModal = () => {
    setSelectedImage(null); // Reset the modal image when closed
  };

  const goToPrevious = () => {
    const currentIndex = images.indexOf(selectedImage);
    const previousIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[previousIndex]);
  };

  const goToNext = () => {
    const currentIndex = images.indexOf(selectedImage);
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
  };

  return (
    <div className="projects">
      <h1>Projects</h1>

      {/* Thumbnail Display */}
      <div className="thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Project ${index + 1}`}
            className={`thumbnail ${selectedImage === image ? "active" : ""}`}
            onClick={() => openModal(image)} // Open modal with clicked image
          />
        ))}
      </div>

      {/* Modal for Full-Size Image */}
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div
            className="modal-navigation"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="nav-arrow nav-arrow-left" onClick={goToPrevious}>
              &#8249; {/* Left arrow icon */}
            </button>
            <button className="nav-arrow nav-arrow-right" onClick={goToNext}>
              &#8250; {/* Right arrow icon */}
            </button>
          </div>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Full-Size Project" />
            <button className="close-button" onClick={closeModal}>
              &times; {/* Close symbol */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
